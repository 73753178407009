
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Action, ActionTypes} from '@/pages/floor/renovation/services/action';
import {$goods, GoodsCategory, GoodsProxy} from '@/pages/floor/renovation/services/goods';
import {$floorExtra} from '@/pages/floor/renovation/services/floor.extra';
import {Floor} from '@/pages/floor/renovation/services/floor';
import GoodsCard from '@/pages/floor/renovation/modules/$components/goods-card/goods-card.vue';
import SetHotspot from '@/pages/floor/renovation/modules/$components/floor-menu-item-editor/components/set-hotspot.vue'
import {getGroupList} from '@/api/auth'
let $goodsCategories: GoodsCategory[] = [];
let $goodsSpecialSubjects: Floor[] = [];
let $childFloors: Floor[] = [];
let $isInit = false;

  @Component({
    name: 'extra-link',
    components: {GoodsCard, SetHotspot}
  })
export default class ExtraOperate extends Vue {
    @PropSync('title', {default: '链接'}) realTitle!: string;
    @PropSync('type') realType!: ActionTypes;
    @PropSync('value') realValue!: any;
    @PropSync('desc') realDesc!: string;
    @PropSync('extraData') realExtraData!: any;
    @PropSync('goodsPreviewBottom') realGoodsPreviewBottom!: boolean;
    @PropSync('errorText') realErrorText!: string;
    @PropSync('setHot', {default: true}) realSetHot!: boolean;
    @PropSync('src') realImgSrc!: any;
    @PropSync('floor') realFloor!: Floor;

    opts = JSON.parse(JSON.stringify(Action.all));
    goods: GoodsProxy = null!;
    goodsCategories = $goodsCategories;
    groupList:Array<any> = [];
    goodsSpecialSubjects = $goodsSpecialSubjects;
    childFloors = $childFloors;
    floorSwitch:any = 1;

    @Watch('realType', {deep: true, immediate: true})
    watchRefresh(newValue) {
      switch (newValue) {
        case 'CATEGORY':
          if (this.goodsCategories.length > 0) return;
          return $goods.getGoodsCategories().then(categories => {
            $goodsCategories = categories;
            this.goodsCategories = categories;
          });
        case 'SPEC_SUBJECT':
          if (this.goodsSpecialSubjects.length > 0) return;
          return $floorExtra.listSubjects(1, 9999).then(subjects => {
            $goodsSpecialSubjects = subjects;
            this.goodsSpecialSubjects = subjects;
          });
        case 'SUB_FLOOR':
          if (this.childFloors.length > 0) return;
          return $floorExtra.listChildFloors().then(childFloors => {
            $childFloors = childFloors;
            this.childFloors = childFloors;
          });
        case 'ACTIVE_GROUP':
          if (this.groupList.length > 0) return;
          const params = {
            page_no: 1,
            page_size: 9999,
            status: 'VALID'
          };
          return getGroupList(params).then(categories => {
            this.groupList = categories.data;
          });
      }
    }

    @Watch('realFloor', {immediate: true})
    watchFloor(newFloor) {
      if (newFloor) {
        this.realFloor.floorSwitch.subscribe(value => {
          if (!value) {
            this.opts.forEach((value, index) => {
              if (value.label === '设置热区') {
                this.opts.splice(index, 1)
              }
            })
          }
        })
      }
    }

    @Watch('floorSwitch', {immediate: true})
    watchFloorSwitch(newFloor) {
      const bool = newFloor - 0
      if (!bool) {
        this.opts.forEach((value, index) => {
          if (value.label === '设置热区') {
            this.opts.splice(index, 1)
          }
        })
      }
    }

    created() {
      if (!$isInit) {
        $isInit = true;
        this.$router.afterEach(() => {
          // 在页面切走的时候置空，可以保证下次进页面的时候数据是最新的
          $goodsCategories = [];
          $goodsSpecialSubjects = [];
          $childFloors = [];
          this.groupList = [];
        });
      }

      if (!this.realSetHot) {
        this.opts.forEach((value, index) => {
          if (value.label === '设置热区') {
            this.opts.splice(index, 1)
          }
        })
      }
    }

    beforeMount() {
      const switchValue:any = sessionStorage.getItem('floorVersions-2.0')
      this.floorSwitch = switchValue - 0
    }

    mounted() {
      console.log(this.realExtraData, "realExtraData")
      switch (this.realType) {
        case 'GOODS':
          if (this.realExtraData) {
            this.goods = new GoodsProxy(this.realExtraData);
            if (this.goods.isEmpty) {
              this.realExtraData = undefined;
              this.realType = 'NONE';
            }
          }
          console.log(this.goods, 'this.goods', this.realExtraData)
      }
    }

    select() {
      switch (this.realType) {
        case 'GOODS':
          return $goods.selectGoods().then(([goods]) => {
            this.realDesc = goods.name;
            this.realValue = goods.id;
            this.realExtraData = goods.toJson();
            this.goods = goods;
            this.emitRecheck();
          });
      }
    }

    emitRecheck() {
      this.$nextTick(() => {
        this.$emit('recheck');
      });
    }

    clearSurplusInfo() {
      this.realValue = '';
      this.realDesc = '';
      this.realExtraData = {};
      this.realErrorText = '';
      this.goods = null!;
      this.emitRecheck();
    }
}
